.__languageswitch {
  .__languageswitch-selector {
    button {
      cursor: pointer;

      .check-icon {
        display: none;
        color: var(--statusGreen);
      }
    }

    button:hover {
      background: var(--hoverColor);
    }

    button.activated {
      opacity: 0.5;
      cursor: default;

      .check-icon {
        display: inline-block;
      }
    }

    button.activated:hover {
      background: inherit;
    }
  }
}

.__language-switch-location-topbar {
  .__languageswitch-selector {
    button {
      display: block;
      width: 100%;
      height: 50px;
      line-height: 50px;
      margin-bottom: 5px;
      cursor: pointer;
      border: 1px solid var(--boxBorderColor);
      border-radius: 5px;
    }
  }
}
