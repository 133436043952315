.__switch {
  .__switch-label-position-left {
    padding-right: 5px;
  }

  .__switch-label-position-right {
    padding-left: 5px;
  }
}

.__switch-size-small {
  .__switch-edit-mode {
    .__switch-label {
      position: relative;
      top: -2px;
    }
  }
}

.__switch-size-medium {
  .__switch-edit-mode {
    .__switch-label {
      position: relative;
      top: -4px;
    }
  }
}

.__switch-size-big {
  .__switch-edit-mode {
    .__switch-label {
      position: relative;
      top: -7px;
    }
  }
}
