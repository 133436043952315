.__cookiebanner-small {
  display: block;
  position: fixed;
  bottom: 0;
  right: 20px;
  cursor: pointer;
  padding: var(--cookiebannerSmallPadding);
  background: var(--cookiebannerSmallBackground);
  border: var(--cookiebannerSmallBorder);
  border-top-left-radius: var(--cookiebannerSmallBorderRadius);
  border-top-right-radius: var(--cookiebannerSmallBorderRadius);
  color: var(--cookiebannerSmallColor);
  font-size: 0.7rem;
}

.__cookiebanner-medium,
.__cookiebanner-settings {
  position: fixed;
  bottom: 0;
  right: 20px;
  width: var(--cookiebannerMediumWidth);
  padding: var(--cookiebannerMediumPadding);
  background: var(--cookiebannerMediumBackground);
  border: var(--cookiebannerMediumBorder);
  border-top-left-radius: var(--cookiebannerMediumBorderRadius);
  border-top-right-radius: var(--cookiebannerMediumBorderRadius);
  color: var(--cookiebannerMediumColor);
  font-size: 0.8rem;

  @media screen and (max-width: $breakSmall) {
    width: 90%;
    right: 5%;
  }
}

.__cookiebanner-title {
  font-weight: 600;
  margin-bottom: 2px;
}

.__cookiebanner-text {
  line-height: 120%;
}

.__cookiebanner-text2 {
  line-height: 120%;
  font-style: italic;
  padding: 8px 0 2px 0;
}

.__cookiebanner-buttons {
  margin-top: 8px;

  button {
    margin-right: 5px;
  }
}

.__cookiebanner-settings-settings {
  padding-top: 5px;
  padding-left: 5px;

  label {
    font-size: inherit;
    cursor: pointer;
  }

  input[type="checkbox"] {
    margin-bottom: 0;
    height: auto;
  }
}
