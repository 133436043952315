.__box {
  border: var(--boxBorder);
  border-radius: var(--boxBorderRadius);
  background: var(--boxBackground);

  padding: var(--boxPadding);
  margin-bottom: var(--boxMargin);

  -webkit-box-shadow: 0px 0px 14px -2px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0px 0px 14px -2px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0px 14px -2px rgba(0, 0, 0, 0.1);
}

.small-box.__box {
  padding: var(--boxSmallPadding);
  margin-bottom: var(--boxSmallMargin);
}

.no-padding.__box {
  padding: 0;
}

.no-margin.__box {
  margin: 0;
}

.clickable.__box {
  cursor: pointer;
}

.clickable.__box:hover {
  background-color: var(--hoverColor);
}
