.__usernotifications-modal {
  background: var(--userNotificationsMenuModal);
  position: fixed;
  top: var(--topbarHeight);
  left: 0;
  width: 100vw;
  height: calc(100vh - var(--topbarHeight));
}

.__usernotifications {
  position: absolute;
  top: 0;
  left: 0;
  width: var(--userNotificationsMenuWidth);
  min-width: var(--userNotificationsMenuWidth);
  max-height: 80vh;
  border: var(--userNotificationsMenuBorder);
  background: var(--userNotificationsMenuBackground);
  text-align: left;
  padding: var(--userNotificationsMenuPadding);
  font-size: 0.9rem;
  overflow: auto;

  @media screen and (max-width: $breakSmall) {
    width: 100%;
  }

  h3 {
    text-align: center;
    font-size: 1.1rem;
    margin-bottom: 15px;
  }

  .__usernotifications-tabs {
    padding: 5px 20px;
    border-top: var(--boxBorder);
    border-bottom: var(--boxBorder);
    font-size: 0.8rem;

    .__tabs-tab {
      padding: 5px 12px !important;
    }
  }

  .__tabs-content {
    margin: 0;
    padding: 0;
  }

  .__usernotifications-no-notifications {
    text-align: center;
    padding: 15px 0;
  }

  .__usernotifications-list-item {
    display: block;
    width: 100%;
    text-align: left;
    border-bottom: var(--boxBorder);
    cursor: pointer;
    padding: 10px 10px;
    display: flex;
    justify-content: flex-start;
    line-height: 115%;

    .__usernotifications-list-item-avatar {
      padding-right: 10px;
    }
  }

  .unread.__usernotifications-list-item {
    background-color: var(--userNotificationsMenuItemUnreadBackground);
  }

  .__usernotifications-list-item:hover {
    background-color: var(--hoverColor);
  }

  .__usernotifications-list-item-content-wrapper {
    display: flex;
    justify-content: flex-start;

    .__usernotifications-list-item-new-indicator {
      padding-left: 10px;
      padding-right: 0px;
      display: flex;
      align-items: center;

      span {
        display: inline-block;
        width: 14px;
        height: 14px;
        border-radius: 50%;
        background: var(--topbarBadgeBackground);
      }
    }
  }

  .__usernotifications-list-item-markas {
    position: relative;
    top: 4px;
    font-size: 0.7rem;
    color: var(--grayColor);

    button {
      color: var(--headingColor);
      cursor: pointer;
    }

    button:hover {
      font-weight: bold;
      text-decoration: underline;
    }
  }
}
