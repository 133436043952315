.__statusindicator {
  display: inline-block;
  height: 18px;
  width: 18px;
  border-radius: 25%;
}

.__statusindicator-status-yes {
  background: var(--statusGreen);
}

.__statusindicator-status-partly {
  background: var(--statusOrange);
}

.__statusindicator-status-no {
  background: var(--statusRed);
}

.__statusindicator-status-true {
  color: var(--statusGreen);
}

.__statusindicator-status-false {
  color: var(--statusRed);
}

.__onlinestatusindicator {
  display: inline-block;
  height: 10px;
  width: 10px;
  border-radius: 50%;
}

.__onlinestatusindicator-status-ONLINE {
  background-color: var(--onlineStatusONLINE);
}

.__onlinestatusindicator-status-AWAY {
  background-color: var(--onlineStatusAWAY);
}

.__onlinestatusindicator-status-OFFLINE {
  background-color: var(--onlineStatusOFFLINE);
}
