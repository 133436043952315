html {
  scroll-behavior: smooth;
}

body {
  background-color: var(--bodyBackground);
  color: var(--textColor);
  font-family: var(--primaryFontFamily);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--headingFontFamily);
}

h1 {
  font-size: 2rem;
}

h2 {
  font-size: 1.6rem;
}

h3 {
  font-size: 1.3rem;
  color: var(--headingColor);
}

.pretitle {
  color: var(--grayColor);
  font-size: 0.9rem;
  font-weight: 600;
  margin-bottom: -3px;
}

div,
p {
  color: var(--textColor);
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.center-vertically {
  display: flex;
  align-items: center;
  height: 100%;
}

.center-vertically-horizontally {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.button {
  margin-bottom: 0;
}

.rounded.button {
  border-radius: 8px;
}

.primary.hollow.button:hover {
  background-color: var(--hoverColor);
}

.__layout-with-topbar {
  padding-top: var(--topbarHeight);
}

main {
  min-height: calc(100vh - var(--topbarHeight));
}

main.with-padding {
  padding-top: var(--padding);
}

main.no-padding {
  padding-top: 0;
}

p.lead {
  font-size: 1.1rem;
}

select {
  appearance: auto;
}

.clickable {
  cursor: pointer;
}

.clickable-text {
  text-decoration: underline;
  cursor: pointer;
}

.clickable-text:hover {
  text-decoration: none;
}

.callout {
  p:last-of-type {
    margin-bottom: 0;
  }
}

.success-text {
  color: var(--statusGreen) !important;
}

.success-background {
  background-color: var(--statusLightGreen) !important;
}

.success-shallow-background {
  background-color: var(--statusShallowGreen) !important;
}

.error-text {
  color: var(--statusRed) !important;
}

.error-background {
  background-color: var(--statusLightRed) !important;
}

.error-shallow-background {
  background-color: var(--statusShallowRed) !important;
}

.color-lightred {
  color: var(--colorLightRed);
}

.color-brightred {
  color: var(--colorBrightRed);
}

.center-on-page {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

footer {
  padding: var(--footerPadding) 0;
  background: var(--footerBackground);
  color: var(--footerColor) !important;
  font-size: var(--footerFontSize);

  a,
  div,
  p {
    color: var(--footerColor) !important;
  }
}

.__tooltip {
  font-size: 0.9rem !important;
  line-height: initial;
}

// .react-datepicker__month-select,
// .react-datepicker__year-select {
//   appearance: initial;
// }
