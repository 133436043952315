.__dialog-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 12000;
  background-color: var(--dialogModalBackground);
  display: flex;
  justify-content: center;
  align-items: center;
}

.__dialog-box {
  background-color: var(--dialogBackground);
  border: var(--dialogBorder);
  border-radius: var(--dialogBorderRadius);
  padding: var(--dialogPadding);
  z-index: 12001;
  width: var(--dialogWidth);

  @media screen and (max-width: $breakSmall) {
    width: 100%;
    height: 100vh;
    overflow: auto;
  }

  .__dialog-buttons {
    text-align: right;
    margin-top: 20px;
  }
}
