.__imageupload-droparea {
  padding: var(--dropareaPadding);
  border: var(--dropareaBorder);
  background: var(--dropareaBackground);
  cursor: pointer;

  .__imageupload-droparea-icon {
    text-align: center;
    margin-bottom: 5px;

    .fa {
      font-size: 1.4rem;
    }
  }

  .__imageupload-droparea-text {
    text-align: center;
  }
}

.__imageupload-droparea:hover {
  border: var(--dropareaHoverBorder);
  background: var(--dropareaHoverBackground);
}

.__imageupload-uploading-popup-content {
  padding: 120px 0;
  text-align: center;

  .__imageupload-uploading-popup-icon {
    margin-bottom: 5px;

    .fa {
      font-size: 1.8rem;
    }
  }
}

.__imageupload-single-image-with-image-wrapper-upload {
  .__imageupload-single-image-with-image-wrapper-upload-cell-upload {
  }

  .__imageupload-single-image-with-image-wrapper-upload-cell-image {
    text-align: right;
  }
}

.__imageupload-single-image-with-image-wrapper-upload.vertical {
  .__imageupload-single-image-with-image-wrapper-upload-cell-upload {
    text-align: center;
    order: 2;
  }

  .imageupload-single-image-with-image-wrapper-upload-cell-image {
    text-align: center;
    order: 1;
  }
}

.__imageupload-single-image-with-image-wrapper-delete-button {
  margin-top: 10px;
  color: #666;
  font-size: 0.9rem;
}
