.__calloutbox {
  margin: 20px 0;

  .__calloutbox-icon {
    float: left;

    .fa {
      margin-top: 8px;
      font-size: 2rem;
    }
  }

  .__calloutbox-content {
    padding-left: 45px;
  }
}

.__calloutbox.info {
  background-color: rgb(245, 245, 245);

  .fa {
    color: rgb(200, 200, 200);
  }
}

.__calloutbox.warning {
  .fa {
    color: #f4ce37c2;
  }
}

.__calloutbox.alert {
  .fa {
    color: #f58e7f;
  }
}

.__calloutbox.success {
  .fa {
    color: #7cdf99;
  }
}
