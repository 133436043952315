.__calculator-box {
  margin: 100px 0 0 0;

  label {
    text-align: center;
    margin-bottom: 10px;
    font-weight: 600;
    font-size: 1.1rem;
  }

  .__calculator-label {
    text-align: center;
    padding-top: 20px;
    margin-bottom: 20px;
  }
}

.horizontal-slider {
  width: 100%;
}

.slider-track {
  border: 2px solid rgb(200, 200, 200);
  border-radius: 4px;
}

.slider-thumb {
  cursor: move;
  position: relative;
  top: -4px;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: var(--primaryColor);
  -webkit-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.5);
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.5);
}

.slider-mark {
  background: rgb(200, 200, 200);
  height: 10px;
  width: 2px;
}

.__calculator-summary-line {
  text-align: center;
}

.type-totalOverall.__calculator-summary-line {
  margin-top: 6px;
  font-size: 0.8rem;
}

.type-periodofnotice.__calculator-summary-line {
  font-size: 0.8rem;
}
