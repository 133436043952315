body {
  font-size: 15px;
}

.small.button {
  font-weight: 500;
}

.tiny.callout {
  font-size: 0.8rem;
  padding: 10px 14px;
  margin: 0;

  div,
  p {
    line-height: 130%;
  }
}

.primary.button {
  background-color: var(--primaryColor);
  border-color: var(--primaryColor);
  color: white;
}

.primary.button:hover {
  background-color: var(--hoverColor);
  border-color: var(--hoverColor);
  color: white;
}

.primary.hollow.button {
  background-color: transparent;
  border-color: var(--primaryColor);
  color: var(--primaryColor);
}

.primary.hollow.button:hover {
  background-color: var(--hoverColor);
  border-color: var(--hoverColor);
  color: white;
}

a {
  color: var(--headingColor);
}

label input[type="checkbox"] {
  margin-bottom: 0;
}
