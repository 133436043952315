.__saving-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 12000;
  background-color: var(--savingModalBackground);
  display: flex;
  justify-content: center;
  align-items: center;
}

.__saving-box {
  background-color: var(--savingBackground);
  border: var(--savingBorder);
  border-radius: var(--savingBorderRadius);
  z-index: 12001;
  width: var(--savingWidth);
  text-align: center;

  @media screen and (max-width: $breakSmall) {
    width: 100%;
    height: 100vh;
    overflow: auto;
  }

  .__saving-box-content {
    padding: var(--savingPadding);
  }

  h3 {
    margin: 10px 0 0 0;
  }

  .loading-spinner-svg {
    height: 40px;
    width: 40px;
  }
}

.__saving-error {
  margin: 20px;
}

.__saving-error-close-message {
  cursor: pointer;
  font-size: 0.8rem;
  color: var(--headingColor);
}

.__saving-error-close-message:hover {
  text-decoration: underline;
}
